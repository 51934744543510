import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import clsx from "clsx"
import Facebook from "../../static/icons/facebook.inline.svg"
import Twitter from "../../static/icons/twitter.inline.svg"
import Beance from "../../static/icons/beance.inline.svg"
import Linkedin from "../../static/icons/linkedin.inline.svg"
import Instagram from "../../static/icons/instagram.inline.svg"
import Burger from "../../static/icons/burger.inline.svg"
import LogoAirbeoneBig from "../../static/logo/airbeone_big_white.inline.svg"
import LogoCircleRed from "../../static/logo/airbeone_circle_red.inline.svg"
import LogoAirbeoneXS from "../../static/logo/airbeone_xs.inline.svg"

const Header: React.FC = () => {
	const [isOpenMenu, setIsOpenMenu] = useState(false)
	const { backgroundHeader } = useStaticQuery(
		graphql`
			query {
				backgroundHeader: file(relativePath: { eq: "background-header.jpg" }) {
					childImageSharp {
						gatsbyImageData(
							width: 1920
							formats: [AUTO, WEBP, AVIF]
							quality: 100
						)
					}
				}
			}
		`
	)
	const image = getImage(backgroundHeader)
	const bgImage = convertToBgImage(image)

	return (
		<BackgroundImage
			alt='airbeone background'
			Tag={`header`}
			className='header'
			id='header'
			{...bgImage}
			preserveStackingContext
		>
			<nav className='menu'>
				<Burger
					className='menu__burger'
					onClick={() => setIsOpenMenu(!isOpenMenu)}
				/>
				<span className='menu__logo-xs'>
					<LogoAirbeoneXS />
				</span>
				<ul className={clsx("menu__list", { active: isOpenMenu })}>
					<li className='menu__list__item'>
						<a href='#airbeone' className='menu__list__item__link'>
							<div className='menu__list__item__link__number'>01.</div>Airbeone
						</a>
					</li>
					<li className='menu__list__item'>
						<a href='#engagements' className='menu__list__item__link'>
							<div className='menu__list__item__link__number'>02.</div>
							Engagements
						</a>
					</li>
					<li className='menu__list__item'>
						<a href='#expertise' className='menu__list__item__link'>
							<div className='menu__list__item__link__number'>03.</div>
							Expertises
						</a>
					</li>
					<li className='menu__list__item'>
						<a href='#realisation' className='menu__list__item__link'>
							<div className='menu__list__item__link__number'>04.</div>
							Réalisations
						</a>
					</li>
					<li className='menu__list__item'>
						<a href='#clientsPartenaires' className='menu__list__item__link'>
							<div className='menu__list__item__link__number'>05.</div>Clients &
							partenaires
						</a>
					</li>
					<li className='menu__list__item'>
						<a href='#contact' className='menu__list__item__link'>
							<div className='menu__list__item__link__number'>06.</div>Contacts
						</a>
					</li>
					<a href='#header' className='logo_circle_red'>
						<LogoCircleRed />
					</a>
				</ul>
				<span className='social-network'>
					<a
						href='https://www.behance.net/airbeone?tracking_source=search_projects_recommended%7Cairbeone'
						target='_blank'
					>
						<Beance />
					</a>
					<a href='https://www.linkedin.com/in/remibonin/' target='_blank'>
						<Linkedin />
					</a>
					<a
						href='https://www.instagram.com/rb_airbeone/?hl=fr'
						target='_blank'
					>
						<Instagram />
					</a>
					<a href='https://www.facebook.com/RBbyAirbeone/' target='_blank'>
						<Facebook />
					</a>
					<a href='https://twitter.com/airbeone?lang=fr' target='_blank'>
						<Twitter />
					</a>
				</span>
			</nav>

			<div className='header__content'>
				<div style={{ position: "relative" }}>
					<div className='header__content__title'>
						Demain s’écrit <br />
						Maintenant <span>.</span>
					</div>
					<div className='header__content__quote'>
						“We can change the world and make it a better place. <br />
						It’s in your hands to make a difference.”
						<div className='header__content__quote__author'>Nelson Mandela</div>
					</div>
					<LogoAirbeoneBig
						className='logo-airbeone-big'
						width={230}
						height={250}
					/>
				</div>
			</div>
		</BackgroundImage>
	)
}

export default Header
