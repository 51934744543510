import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const YouAre: React.FC = () => {
	return (
		<section className='youAre'>
			<StaticImage
				src='../../static/logo/logo-grey.svg'
				alt='airbeone'
				className='logo'
				quality={100}
			/>
			<figure>
				<StaticImage
					src='../images/ong-1.jpg'
					alt='ong'
					className='image'
					quality={100}
				/>
				<figcaption>Une ONG</figcaption>
			</figure>
			<figure>
				<StaticImage
					src='../images/particular.jpg'
					alt='un particulier'
					className='image'
					quality={100}
				/>
				<figcaption>Un particulier</figcaption>
			</figure>
			<figure>
				<StaticImage
					src='../images/assoc.jpg'
					alt='une association'
					className='image'
					quality={100}
				/>
				<figcaption>Une association</figcaption>
			</figure>
			<figure>
				<StaticImage
					src='../images/business.jpg'
					alt='une entreprise'
					className='image'
					quality={100}
				/>
				<figcaption>Une entreprise</figcaption>
			</figure>
			<figure>
				<StaticImage
					src='../images/collectivity.jpg'
					alt='une collectivité'
					className='image'
					quality={100}
				/>
				<figcaption>Une collectivité</figcaption>
			</figure>
			<span className='title'>Vous êtes ...</span>
		</section>
	)
}

export default YouAre
