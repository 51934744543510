import React from "react"
import Fade from "react-reveal/Fade"
import { Engagement } from "@/data/engagement"
import getIcon from "@/utils/getIcon"

type Props = {
	engagement: Engagement
}

const EngagementItem = ({ engagement }: Props) => {
	return (
		<Fade
			bottom
			duration={1000}
			delay={600}
			distance='30px'
			key={engagement.id}
		>
			<div className='engagement__container__article__content-left'>
				{getIcon(engagement.id)}
				<h6>{engagement.name}</h6>
				<p>{engagement.text1}</p>
				<br />
				<p>{engagement.text2}</p>
			</div>
		</Fade>
	)
}

export default EngagementItem
