import React, { ReactNode } from "react"

type Props = {
	children: ReactNode
	className?: any
}

const Container = ({ className, children }: Props) => (
	<div className={`${className} container`}>{children}</div>
)

export default Container
