import React from "react"
import LogoXsYellow from "../../static/logo/airbeone_xs_yellow.inline.svg"
import IconDoigt from "../../static/icons/doigt_fleche.inline.svg"
import Container from "./Container"
import { StaticImage } from "gatsby-plugin-image"

const Values: React.FC = () => (
	<section className='values'>
		<Container className='values__container'>
			<div className='finger'>
				<hr />
				<IconDoigt />
				<hr />
			</div>
			<article className='values__container__article'>
				<div className='values__container__article__content'>
					<h5 className='values__container__article__content__title'>
						Nous avons des valeurs <span className='text-yellow'>communes</span>
					</h5>
					<p className='text-grey'>
						Ensemble nous sommes meilleurs quand nous adhérons à des valeurs et
						des engagements partagés.{" "}
					</p>
				</div>
				<LogoXsYellow />
			</article>
		</Container>
	</section>
)

export default Values
