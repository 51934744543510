import React from "react"

const Contact: React.FC = () => (
	<section className='contact' id='contact'>
		<div className='content-form'>
			<h3>
				C<span>o</span>ntactez-nous
			</h3>
			<form
				method='POST'
				action='https://getform.io/f/8c1ee305-5e67-446d-b6d3-9ce2da6c7c26'
			>
				<div className='grid'>
					<label htmlFor='lastName'>
						Nom <br />
						<input type='text' name='lastName' id='lastName' required></input>
					</label>
					<label htmlFor='firstName'>
						Prénom <br />
						<input type='text' name='firstName' id='firstName' required></input>
					</label>
					<label htmlFor='phoneNumber'>
						Téléphone
						<br />
						<input type='text' name='phoneNumber' id='phoneNumber'></input>
					</label>
					<label htmlFor='email'>
						Adresse mail
						<br />
						<input
							type='email'
							name='email'
							id='email'
							required
							pattern='^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
						></input>
					</label>
					<label htmlFor='subject'>
						Objet
						<br />
						<input type='text' name='subject' id='subject' />
					</label>
				</div>
				<label htmlFor='message'>
					Message
					<br />
					<textarea id='message' name='message' rows={14} required />
				</label>
				<div className='content-button'>
					<button type='submit'>Envoyer</button>
				</div>
			</form>
		</div>
	</section>
)
export default Contact
