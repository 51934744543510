import React from "react"
import { PageProps } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "@/components/Header"
import Realization from "@/components/Realization"
import Contact from "@/components/Contact"
import Footer from "@/components/Footer"
import UnderContact from "@/components/UnderContact"
import Reference from "@/components/Reference"
import Airbeone from "@/components/Airbeone"
import Agency from "@/components/Agency"
import Values from "@/components/Values"
import Engagement from "@/components/Engagement/Engagement"
import Project from "@/components/Project"
import YouAre from "@/components/YouAre"
import CustomersAndPartners from "@/components/CustomersAndPartners"
import favicon from "../images/favicon.ico"
import "../style/main.scss"

const Home: React.FC<PageProps> = () => (
	<>
		<Helmet>
			<meta charSet='utf-8' />
			<title>Airbeone</title>
			<html lang='fr' />
			<meta name='description' content='Airbeone' />
			<link rel='icon' href={favicon} />
		</Helmet>
		<main>
			<Header />
			<Agency />
			<Airbeone />
			<Engagement />
			<Project />
			<YouAre />
			<Values />
			<Realization />
			<CustomersAndPartners />
			<Reference />
			<Contact />
			<UnderContact />
			<Footer />
		</main>
	</>
)

export default Home
