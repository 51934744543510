import React from "react"
import IconListen from "../../static/icons/listen.inline.svg"
import IconSharing from "../../static/icons/sharing.inline.svg"
import IconAudacity from "../../static/icons/audacity.inline.svg"
import IconPleasure from "../../static/icons/pleasure.inline.svg"

const getIcon = (iconId) => {
	switch (iconId) {
		case 0:
			return <IconListen />
		case 1:
			return <IconSharing />
		case 2:
			return <IconAudacity />
		case 3:
			return <IconPleasure />
		default:
			break
	}
}

export default getIcon
