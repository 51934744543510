export type Engagement = {
	name: string
	id: number
	text1: string
	text2: string
}

const engagementList: Engagement[] = [
	{
		name: "Ecoute",
		id: 0,
		text1:
			"C’est le fondement de toute relation humaine et reste une preuve d'estime et de respect pour tous clients.",
		text2:" Nous nous attachons à comprendre votre histoire, vos contraintes pour mieux définir un plan stratégique adapté à vos objectifs.",
	},
	{
		name: "Partage",
		id: 1,
		text1:
			"Partager, c'est être là, présent à vos côtés.",
		text2:" C'est avant tout vivre une expérience commune tout au long de l'histoire que nous écrivons.",},
	{
		name: "Audace",
		id: 2,
		text1:
			"L'envie de prendre des risques et de s'engager, dans le respect d'un principe de prudence et de lucidité.",
		text2:"Nous saurons relever vos défis ensemble pour atteindre vos objectifs.",},
	{
		name: "Plaisir",
		id: 3,
		text1:
			"C'est l'enrichissement qui oeuvre à un épanouissement et procure de la satisfaction lors d'un projet réussi.",
		text2:"Cette sensation doit être le moteur des relations que nous tissons.",},
]

export default engagementList