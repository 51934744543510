import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Reference: React.FC = () => (
	<section className='reference'>
		<h3>
			N<span>o</span>s partenaires
		</h3>
		<div className='flex-container'>
			<a href='http://www.la-coulisse.com/' target='_blank'>
				<StaticImage
					alt='logo lacoulisse'
					src='../images/logo/logo-lacoulisse.png'
					className='logo'
					quality={100}
				/>
			</a>
			<a href='https://www.regieair.com/' target='_blank'>
				<StaticImage
					alt='logo regie air'
					src='../images/logo/logo-regie.png'
					quality={100}
				/>
			</a>
		</div>
		<div className='flex-container'>
			<a href='https://www.comgax.com/' target='_blank'>
				<StaticImage
					alt='logo gax'
					src='../images/logo/logo-gax.png'
					quality={100}
				/>
			</a>
		</div>
	</section>
)
export default Reference
