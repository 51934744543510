import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Project: React.FC = () => {
	return (
		<section className='project' id='expertise'>
			<article className='project__one'>
				<span className='project__one__bookmark'>I</span>
				<div className='project__one__content'>
					<h5>Imaginons votre projet</h5>
					<ul>
						<li>Analyse et diagnostic de votre idée</li>
						<li>Création du concept événementiel</li>
						<li>Recommandations stratégiques</li>
						<li>Gestion des appels d'offre</li>
						<li>Soutenance & présentations</li>
					</ul>
				</div>
				<StaticImage src='../images/LAMP.jpg' alt='projet' quality={100} />
			</article>
			<article className='project__two'>
				<StaticImage src='../images/hands.jpg' alt='projet' quality={100} />
				<div className='project__two__content'>
					<h5>Concevoir un événement responsable</h5>
					<ul>
						<li>Cadrage des enjeux durables</li>
						<li>Elaboration de la stratégie</li>
						<li>Création d'un référentiel d'engagements</li>
						<li>Sensibilisation des parties prenantes</li>
						<li>Evaluer les actions et impacts</li>
					</ul>
				</div>
				<span className='project__two__bookmark'>II</span>
			</article>
			<article className='project__three'>
				<span className='project__three__bookmark'>III</span>
				<div className='project__three__content'>
					<h5>Produire vos événements</h5>
					<ul>
						<li>Coordination budgétaire et administrative</li>
						<li>Elaboration des dossiers d'instructions</li>
						<li>Pilotage des équipes et parties prenantes</li>
						<li>Gestion de la logistique</li>
						<li>Conseil en scénographie</li>
					</ul>
				</div>
				<StaticImage src='../images/hand.jpg' alt='projet' quality={100} />
			</article>
			<article className='project__four'>
				<StaticImage
					src='../images/4.jpg'
					alt='projet'
					width={900}
					height={900}
					quality={100}
				/>
				<div className='project__four__content'>
					<h5>Gestion des opérations sur site</h5>
					<ul>
						<li>Etude de faisabilité technique</li>
						<li>Conception des plans</li>
						<li>Respect des réglementations ERP</li>
						<li>Accueil des publics</li>
						<li>Prévention des risques sanitaires et sécuritaires</li>
					</ul>
				</div>
				<span className='project__four__bookmark'>IV</span>
			</article>
		</section>
	)
}

export default Project
