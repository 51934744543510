import React from "react"
import logo from "images/logo.svg"
import Facebook from "../../static/icons/facebook.inline.svg"
import Twitter from "../../static/icons/twitter.inline.svg"
import Beance from "../../static/icons/beance.inline.svg"
import Linkedin from "../../static/icons/linkedin.inline.svg"
import Instagram from "../../static/icons/instagram.inline.svg"

const Footer: React.FC = () => (
	<footer>
		<div className='information'>
			<img src={logo} alt='logo-footer' />
			<span className='address'>
				<b>Airbeone</b> <br />{" "}
				<span>Siège: 7 Rue Paul Bert, 63000 Clermont-Fd</span>
			</span>
			<hr />
			<span className='menu'>
				<ul>
					<li>
						<a href='#airbeone'>Airbeone</a>
					</li>
					<li>
						<a href='#clientsPartenaires'>Clients</a>
					</li>
					<li>
						<a href='#expertise'>Expertises</a>
					</li>
					<li>
						<a href='#clientsPartenaires'>&partenaires</a>
					</li>
					<li>
						<a href='#realisation'>Réalisation</a>
					</li>
					<li>
						<a href='#contact'>Contacts</a>
					</li>
				</ul>
			</span>
			<span className='author'>
				<span>
					Rémi bonin <br />
					06 63 81 70 28 <br />
					<a href='mailto:remi@airbeone.com'>remi@airbeone.com</a> <br />
				</span>
				<a href='/legal-notice' target='_blank'>
					Mentions légales
				</a>
			</span>
			<span className='social-network'>
				<a href='https://www.linkedin.com/in/remibonin/' target='_blank'>
					<Linkedin />
				</a>
				<a href='https://www.instagram.com/rb_airbeone/?hl=fr' target='_blank'>
					<Instagram />
				</a>
				<a href='https://www.facebook.com/RBbyAirbeone/' target='_blank'>
					<Facebook />
				</a>
				<a href='https://twitter.com/airbeone?lang=fr' target='_blank'>
					<Twitter />
				</a>
				<a
					href='https://www.behance.net/airbeone?tracking_source=search_projects_recommended%7Cairbeone'
					target='_blank'
				>
					<Beance />
				</a>
			</span>
		</div>
		<div className='copyright'>
			<span>Airbeone ©2021</span>
			<span>
				Design by&nbsp;
				<a href='https://www.comgax.com/' target='_blank' rel='noreferrer'>
					Gax
				</a>
				&nbsp;& Develop by&nbsp;
				<a
					href='https://www.malt.fr/profile/blandinerondel'
					target='_blank'
					rel='noreferrer'
				>
					WildCöde
				</a>
			</span>
		</div>
	</footer>
)

export default Footer
