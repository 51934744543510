import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "./Container"
import { convertToBgImage } from "gbimage-bridge"

const CustomersAndPartners: React.FC = () => {
	const { backgroundMeduse } = useStaticQuery(
		graphql`
			query {
				backgroundMeduse: file(relativePath: { eq: "background-meduse.jpg" }) {
					childImageSharp {
						gatsbyImageData(
							width: 1920
							formats: [AUTO, WEBP, AVIF]
							quality: 100
						)
					}
				}
			}
		`
	)
	const image = getImage(backgroundMeduse)
	const bgImage = convertToBgImage(image)

	return (
		<BackgroundImage
			alt='clients et partenaires'
			Tag={`section`}
			className='customersAndPartners'
			id='clientsPartenaires'
			{...bgImage}
			preserveStackingContext
		>
			<Container className='customersAndPartners__container'>
				<article className='customersAndPartners__container__article1'>
					<h2 className='customersAndPartners__container__article1__title'>
						Airbeone s’appuie sur un réseau de{" "}
						<span className='text-red'>professionnels</span> et{" "}
						<span className='text-blue'>talents confirmés</span>
					</h2>
					<p className='customersAndPartners__container__article1__p1'>
						{">"} Directeurs de production, coordinateurs techniques, directeurs
						artistiques, logisticiens, prestataires, artistes, chefs de projets
					</p>
					<p className='customersAndPartners__container__article1__p2'>
						Ils vous accompagnent depuis le brief jusqu’à la post production en
						réunissant l’ensemble des compétences nécessaires à la réussite de
						votre événement.
					</p>
				</article>
				<article className='customersAndPartners__container__article2'>
					<h3 className='customersAndPartners__container__article2__title'>
						N<span className='text-yellow'>o</span>s engagements
					</h3>
					<ul className='customersAndPartners__container__article2__list'>
						<a
							href='https://www.reseau-eco-evenement.net/'
							target='_blank'
							className='logo-reeve'
						>
							<StaticImage
								alt='logo reeve'
								src='../images/logo/reeve.png'
								quality={100}
							/>
						</a>
						<a
							href='https://www.solidarite-sida.org/'
							target='_blank'
							className='logo-sida'
						>
							<StaticImage
								alt='logo solidarite sida'
								src='../images/logo/solidarite-sida.png'
								quality={100}
							/>
						</a>
						<a
							href='https://freelances-evenementiel.org/'
							target='_blank'
							className='logo-freelances'
						>
							<StaticImage
								alt='logo freelances'
								src='../images/logo/freelances.png'
								quality={100}
							/>
						</a>
						<a
							href='https://www.independants.co/'
							target='_blank'
							className='logo-indep'
						>
							<StaticImage
								alt='logo indep'
								src='../images/logo/indep.png'
								quality={100}
							/>
						</a>
						<a
							href='https://www.ledamier.fr/'
							target='_blank'
							className='logo-sp'
						>
							<StaticImage
								alt='logo le damier'
								src='../images/logo/damier.png'
								quality={100}
							/>
						</a>
						<p className='text-reeve'>
							Mobiliser les acteurs de la filière événementielle contre le
							dérèglement climatique
						</p>

						<p className='text-sida'>Association de lutte contre le VIH SIDA</p>

						<p className='text-freelances'>
							Réseau social et professionnel des acteurs de l'évènement
						</p>

						<p className='text-indep'>
							Syndicat des indépendants professionnels
						</p>

						<p className='text-sp'>
							Cluster d'Entreprises Culturelles et Créatives de Clermont-Ferrand
						</p>
					</ul>
				</article>
			</Container>
		</BackgroundImage>
	)
}

export default CustomersAndPartners
