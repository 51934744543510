import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const UnderContact: React.FC = () => (
	<section className='under-contact'>
		<StaticImage
			alt='logo remi'
			src='../images/logo/remi.png'
			className='logo'
			quality={100}
		/>
	</section>
)
export default UnderContact
