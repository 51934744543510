import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import engagementList, { Engagement } from "@/data/engagement"
import getIcon from "@/utils/getIcon"
import { convertToBgImage } from "gbimage-bridge"
import EngagementItem from "./EngagementItem"
import OurEngagements from "../../../static/bookmark/engagement.inline.svg"
import Container from "../Container"
import clsx from "clsx"

const EngagementList: React.FC = () => {
	const [selectText, setSelectText] = useState<Engagement>(engagementList[0])
	const { backgroundEngagement } = useStaticQuery(
		graphql`
			query {
				backgroundEngagement: file(
					relativePath: { eq: "background-engagement.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(
							width: 1920
							formats: [AUTO, WEBP, AVIF]
							quality: 100
						)
					}
				}
			}
		`
	)
	const image = getImage(backgroundEngagement)
	const bgImage = convertToBgImage(image)

	return (
		<BackgroundImage
			alt='engagement background'
			Tag={`section`}
			className='engagement'
			id='engagements'
			{...bgImage}
			preserveStackingContext
		>
			<Container className='engagement__container'>
				<div className='engagement__container__bookmark'>
					<OurEngagements />
				</div>
				<article className='engagement__container__article'>
					<EngagementItem engagement={selectText} />
					<ul className='engagement__container__article__list'>
						{engagementList.map((engagement: Engagement) => {
							return (
								<li
									onClick={() => setSelectText(engagement)}
									key={engagement.id}
									className={clsx(
										"engagement__container__article__list__item",
										{ active: engagement.id === selectText.id }
									)}
								>
									{getIcon(engagement.id)}
									{engagement.name}
								</li>
							)
						})}
					</ul>
				</article>
			</Container>
		</BackgroundImage>
	)
}

export default EngagementList
