import React from "react"
import Container from "./Container"

const Agency: React.FC = () => (
	<section className='agency'>
		<Container className='agency__container'>
			<h1 className='agency__container__title'>
				Événementiel en <br />
				transition, <span className='text-blue'>durable</span> <br /> &{" "}
				<span className='text-red'>
					engagé<span className='dot'>.</span>
				</span>
			</h1>
			<p className='agency__container__content'>
				Agence de conseil en événementiel, Airbeone est avant tout une{" "}
				<b className='text-bold'>structure militante</b> qui intègre une
				démarche{" "}
				<b className='text-bold'>d’engagement durable et de solidarité</b>{" "}
				depuis sa création. <br />
				<br />
				Au quotidien elle réunit autour d’elle des personnes{" "}
				<b className='text-bold'>inspirantes</b>, convaincues du{" "}
				<b className='text-bold'>sens</b> donné à leur profession.
			</p>
		</Container>
	</section>
)

export default Agency
