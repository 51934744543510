import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { convertToBgImage } from "gbimage-bridge"
import Beance from "../../static/icons/beance.inline.svg"
import Container from "./Container"

const Realization: React.FC = () => {
	const { backgroundRealization } = useStaticQuery(
		graphql`
			query {
				backgroundRealization: file(
					relativePath: { eq: "background-realization.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(
							width: 1920
							formats: [AUTO, WEBP, AVIF]
							quality: 100
						)
					}
				}
			}
		`
	)
	const image = getImage(backgroundRealization)
	const bgImage = convertToBgImage(image)

	return (
		<BackgroundImage
			alt='realization background'
			Tag={`section`}
			className='realization'
			id='realisation'
			{...bgImage}
			preserveStackingContext
		>
			<Container className='realization__container'>
				<article className='realization__container__article'>
					<p>
						Pour découvrir nos réalisations, rendez-vous sur notre{" "}
						<a
							href='https://www.behance.net/airbeone?tracking_source=search_projects_recommended%7Cairbeone'
							target='_blank'
							className='text-yellow'
						>
							portfolio <Beance width={73} height={73} />
						</a>
					</p>
				</article>
			</Container>
		</BackgroundImage>
	)
}

export default Realization
