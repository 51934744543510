import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import LogoCircleRed from "../../static/logo/airbeone_circle_red.inline.svg"
import Container from "./Container"

const Airbeone = () => {
	const { backgroundAirbeone } = useStaticQuery(
		graphql`
			query {
				backgroundAirbeone: file(
					relativePath: { eq: "background-airbeone.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(
							width: 1920
							formats: [AUTO, WEBP, AVIF]
							quality: 100
						)
					}
				}
			}
		`
	)
	const image = getImage(backgroundAirbeone)
	const bgImage = convertToBgImage(image)

	return (
		<BackgroundImage
			alt='airbeone background'
			Tag={`section`}
			id='airbeone'
			className='airbeone'
			{...bgImage}
			preserveStackingContext
		>
			<Container className='airbeone__container'>
				<div className='airbeone__container__bookmark'>
					<LogoCircleRed />
				</div>
				<article className='airbeone__container__article'>
					<h2>Parce qu’il y a urgence à agir,</h2>
					<p>
						<b className='text-bold'>
							Nous devons repenser la manière de produire les événements de
							demain.{" "}
						</b>
					</p>
					<br />
					<br />
					<p>
						La question de l’
						<b className='text-bold'>
							écoresponsabilité et de la transition écologique{" "}
						</b>
						s’impose comme une évidence dans notre vie de tous les jours.{" "}
					</p>
					<br />
					<br />
					<p>
						Comment intégrer cette démarche dans l’organisation de manifestation
						tout en ayant à souhait de réduire notre empreinte carbone, de
						limiter nos déchets, d'intégrer les notions de recyclage et de
						consommation raisonnée.
					</p>
					<br />
					<br />
					<p>
						Tout cela en valorisant l'écosystème local et étant le plus inclusif
						possible.
					</p>
					<br />
					<br />
					<p>
						<b className='text-bold'>
							Airbeone vous accompagne sur vos projets tout en inspirant le
							changement.
						</b>
					</p>
				</article>
			</Container>
		</BackgroundImage>
	)
}

export default Airbeone
